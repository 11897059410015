import './Card.scss';
import CardHeader from '../CardHeader/CardHeader';

function Card({ children }) {
    
    return (
        <>
        <CardHeader />
        <div className="card">
            { children }
        </div>
        </>
    );

}

export default Card;