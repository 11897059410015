import './Void.scss';
import FaceBookIcon from '../../assets/logos/Rhodes_FB.png';
import InstagramIcon from '../../assets/logos/Rhodes_Instagram.png';
import WeChatIcon from '../../assets/logos/Rhodes_WeChat.png';
import {
    INSTAGRAM_URL, 
    FACEBOOK_URL,
    WECHAT_URL,
    STORES_URL,
    DINING_URL,
    WHATS_ON_URL
} from '../../utilities/WEB_URLs';

function Void() {

    return (
        <div className="void">
            <h1 className="headline headline--highlight">Create new traditions</h1>
            <p className="heading">Your Pandamonium prize voucher has now expired.</p>
            <p className="text-alt">We hope you enjoy your prize! Thanks for playing Pandamonium at Rhodes Central.</p>
            <ul className="list-default void__socials">
                <li className="void__item">
                    <a href={INSTAGRAM_URL}>
                        <img className="void__img" src={InstagramIcon} alt="instagram" />
                    </a>
                </li>
                <li className="void__item">
                    <a href={FACEBOOK_URL}>
                        <img className="void__img" src={FaceBookIcon} alt="facebook" />
                    </a>
                </li>
                <li className="void__item">
                    <a href={WECHAT_URL}>
                        <img className="void__img" src={WeChatIcon} alt="wechat" />
                    </a>
                </li>
            </ul>
            <ul className="list-default void__buttons">
                <li className="void__item">
                    <a href={STORES_URL} className="button link-default text-button" type="button">stores</a>
                </li>
                <li className="void__item">
                    <a href={DINING_URL} className="button link-default text-button" type="button">dining</a>
                </li>
                <li className="void__item">
                    <a href={WHATS_ON_URL} className="button link-default text-button" type="button">what's on</a>
                </li>
            </ul> 
        </div>
    );
    
}

export default Void;