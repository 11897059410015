import Request from "../utility/Request.js";

class RedeemedVoucher extends Request {
  constructor() {
    super("get", ``);
  }
  sendRequest(id) {
    this.url = `redeemed-voucher/${id}`;
    return this.sendRequestPromise({});
  }

  isVoucherCurrent(result) {

    const { voucher, currentTimestamp } = result.data;

    // If the voucher has been redeemed, add a RemainingTime property
    if (voucher.RedeemedTimestamp) {
      voucher.RemainingTime =
        5 * 60 * 1000 - (currentTimestamp - voucher.RedeemedTimestamp);
    }

    /*
    // The voucher has expired if the current time is past the expiry time or
    // if the voucher was recieved more than 60 days ago or
    // if the voucher has been redeemed more than 5 minutes ago
    voucher.Expired =
      currentTimestamp > ExpireTimestamp ||
      currentTimestamp - voucher.ReceivedTimestamp > 60 * 24 * 60 * 60 * 1000 ||
      (typeof voucher.RemainingTime !== "undefined" &&
        voucher.RemainingTime < 0);

    */
    // The voucher has expired if the current time is past the expiry time or
    // if the voucher has been redeemed more than 5 minutes ago
    voucher.Expired =
      currentTimestamp > voucher.ExpireTimestamp ||
      (typeof voucher.RemainingTime !== "undefined" &&
        voucher.RemainingTime < 0);

    // Delete redundant properties
    delete voucher.ReceivedTimestamp;
    delete voucher.RedeemedTimestamp;
    if (voucher.Expired) {
      delete voucher.RemainingTime;
    }
  }

  resolveRequest(result, resolve) {
    this.isVoucherCurrent(result);
    resolve(result.data.voucher);
  }
}

export default RedeemedVoucher;
