import Controller from "./Controller.js";
// Without Authorizer
import GetVoucher from "./routes/RedeemedVoucher.js";
import RedeemVoucher from "./routes/RedeemVoucher.js";

let getVoucher = new GetVoucher();
Controller.prototype.getVoucher = getVoucher.sendRequest.bind(getVoucher);

let redeemVoucher = new RedeemVoucher();
Controller.prototype.redeemVoucher =
  redeemVoucher.sendRequest.bind(redeemVoucher);

let SDK = new Controller();

export default SDK;
