import Request from "../utility/Request.js";

class RedeemVoucher extends Request {
  constructor() {
    super("get", ``);
  }
  sendRequest(id) {
    this.url = `redeem-voucher/${id}`;
    return this.sendRequestPromise({});
  }
  resolveRequest(result, resolve) {
    console.log(result);
    resolve(result.data.voucher);
  }
}

export default RedeemVoucher;
