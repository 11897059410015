import './ButtonsBar.scss';

function ButtonsBar({ children }) {
    return (
        <div className="buttons">
            { children }
        </div>
    );
}

export default ButtonsBar;