import './Header.scss';
import RhodesLogo from '../../assets/logos/rhodes-logo.png';

function Header() {
    return (
        <header className="header">
            <img className="header__logo" src={RhodesLogo} alt="Rhodes Central" />            
        </header>
    );
}

export default Header;