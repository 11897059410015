import Controller from "../Controller.js";
import axios from "axios";

axios.defaults.baseURL = Controller.baseUrl;
// axios.defaults.withCredentials = true;
// axios.defaults.credentials = "include";

class Request {
  constructor(method, url, isFormData) {
    this.method = method;
    this.url = url;
    this.isFormData = isFormData;
  }
  resolveRequest(result, resolve) {
    resolve(result);
  }
  rejectRequest(error, reject) {
    reject(error);
  }

  sendRequest() {
    return this.sendRequestPromise({});
  }

  sendRequestPromise(data) {
    return new Promise((resolve, reject) => {
      let config = {
        method: this.method,
        url: this.url,
        data: this.method === "get" ? undefined : data,
        // headers: { Authorization: Controller.idToken },
      };
      if (this.isFormData) {
        config.headers[
          "content-type"
        ] = `multipart/form-data; boundary=${data._boundary}`;
      }
      axios(config)
        .then((result) => {
          this.resolveRequest(result, resolve);
        })
        .catch((error) => {
          switch (error.response?.status) {
            case 400: // Bad request
              break;
            case 401: // Unauthenticated
              break;
            case 403: // Forbidden
              break;
            case 404: // Not found
              break;
            case 405: // Not allowed
              break;
            case 422: // Unprocessable
              break;
            case 500: // Internal server error
              break;
          }
          this.rejectRequest(error, reject);
        });
    });
  }
}

export default Request;
