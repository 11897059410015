import './CardHeader.scss';
import BambooImg from '../../assets/backgrounds/bamboo-leaves.png';
import PandaImg from '../../assets/backgrounds/panda.png';

function CardHeader() {
    return (
        <header className="card-header">
            <div className="card-header__canvas">
                <img className="card-header__img" src={BambooImg} alt="bamboo-leaves" />
            </div>
            <div className="card-header__canvas card-header__canvas--panda">
                <img className="card-header__img" src={PandaImg} alt={"panda"} />
            </div>
        </header>
    );
}

export default CardHeader;