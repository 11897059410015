import './Voucher.scss';
import { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { VOID_URL } from '../../utilities/APP_URLs';
import Modal from '../../components/Modal/Modal';
import Clock from '../../components/Clock/Clock';
import ButtonsBar from '../../components/ButtonsBar/ButtonsBar';
// API
import SDK from '../../rhodes-voucher-landing-sdk/src/main.js';
// 

function Voucher(props) {

    const [ voucher, setVoucher ] = useState();
    const [ openModal, setOpenModal ] = useState(false);
    const [ invalidURL, setInvalidURL ] = useState(false);
    const { voucherId } = props.match.params;

    useEffect(() => loadVoucherData(voucherId), [voucherId]);

    const loadVoucherData = voucherId => {
        SDK
            .getVoucher(voucherId)
            .then(resp => setVoucher(resp))
            .catch(() => setInvalidURL(true));
    };

    const handleRedeemVoucher = () => {
        SDK
            .redeemVoucher(voucherId)
            .then(() => {
                if (!voucher?.RemainingTime) {
                    const RemainingTime = 300000;
                    return setVoucher({...voucher, RemainingTime});
                }
            })
            .catch(err => console.error(err));

        setOpenModal(false);
    };

    if (invalidURL || voucher?.Expired) return <Redirect to={VOID_URL} />

    // Better handle no voucher error
    if (!voucher) return null;

    return (
        <>
        <div className="voucher__canvas">
            <img className="voucher__img" src={voucher?.Image} alt={`voucher for ${voucher.Terms}`} />
        </div>
        <div className="voucher__content">
            { openModal && 
                <Modal>
                    <h2 className="headline headline--warning">Are you sure you’re ready to redeem the offer?</h2>
                    <p className="text-default">Please present this voucher to staff at Rhodes Central BEFORE you click ‘Yes’ below. Remember, once you click ‘Yes’, your will expire after 5 minutes</p>
                </Modal>
            }
            { voucher?.RemainingTime &&
                <Modal>
                    <Clock millisecondsRemaining={voucher.RemainingTime} />
                </Modal>
            }
            <h2 className="heading border-default">Valid until {voucher.Expiration}</h2>
            <h2 className="heading">IMPORTANT:</h2>
            <p className="text-default">Only click the ‘Redeem’ button below once you have presented this voucher to staff and are ready to redeem your prize. This voucher will then become void after 5 minutes.</p>
        </div>
        <ButtonsBar>
            { !openModal && !voucher?.RemainingTime &&
                <button className="button" onClick={() => setOpenModal(true)}>redeem</button>
            }
            { openModal && !voucher?.RemainingTime &&
                <>
                <button className="button" type="button" onClick={handleRedeemVoucher}>redeem</button>
                <button className="button" type="button" onClick={() => setOpenModal(false)}>cancel</button>
                </>
            }
        </ButtonsBar>
        </>
    );

}

export default Voucher;