import './App.scss';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { VOUCHER_URL, VOID_URL } from './utilities/APP_URLs'
import Card from './components/Card/Card';
import Voucher from './pages/Voucher/Voucher';
import Void from './pages/Void/Void';
import Header from './components/Header/Header';
import BambooBackground from './assets/backgrounds/bamboo.jpg';

function App() {

  return (
    <div className="App">
      <img className="background" src={BambooBackground} alt="bamboo" />
      <Header />
      <div className="App__content">
        <Card>
          <Router>
            <Switch>
              <Route path={VOID_URL} component={Void} />
              <Route path={`${VOUCHER_URL}/:voucherId`} component={Voucher} />
            </Switch>
          </Router>
        </Card>
      </div>
    </div>
  );

}

export default App;