import './Clock.scss';
import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { VOID_URL } from '../../utilities/APP_URLs';

function Clock({ millisecondsRemaining }) {

    const [ time, setTime ] = useState(0);
    const [ runTimer, setRunTimer ] = useState(true);

    useEffect(() => {
        let countdown;

        if (runTimer) {
            setTime(Math.round(millisecondsRemaining / 1000));
            countdown = setInterval(() => {
                setTime(time => time - 1);
            }, 1000)
        } else {
            clearInterval(countdown)
        }

        return () => clearInterval(countdown);
    }, [millisecondsRemaining, runTimer]);

    useEffect(() => {
        if (time < 0 && runTimer) {
            setRunTimer(false);
            setTime(0);
        }
    }, [time, runTimer]);

    const seconds = String(time % 60).padStart(2, 0);
    const minutes = String(Math.floor(time / 60)).padStart(2, 0);

    if (time <= 0 && !runTimer) return <Redirect to={VOID_URL} />

    return (
        <div className="countdown">
            <div className="countdown__clock">
                <h1 className="headline-clock">Time Left:</h1>
                <h1 className="headline-clock">{minutes}:{seconds}</h1>
            </div>
            <p className="text-default">Congratulations on claiming your Pandamonium prize! You may close this window when complete.</p>
        </div>
    );
}

export default Clock;