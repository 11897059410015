import './Modal.scss';

function Modal({ children }) {

    return (
        <section className="modal">
            <div className="modal__content">
                { children }
            </div>
        </section>
    );
    
}

export default Modal;